import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, styled } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import TripTable from "./TripTable";

const RoundButton = styled(Button)({
  borderRadius: 20,
  backgroundColor: "#5FA8D3",
  color: "white",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#366581",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
});
const AppleTitle = styled(Typography)({
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 40,
  fontWeight: "bold",
});

const useStyles = makeStyles({
  rootWeb: {
    marginTop: 130,
    marginLeft: 50,
    marginRight: 50,
  },
  rootMobile: {
    marginTop: 130,
    marginLeft: 15,
    marginRight: 15,
  },

  add: {
    borderRadius: 50,
  },
  table: {
    paddingTop: 20,
  },
});

const Home = () => {
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <Grid
      className={windowSize[0] > 600 ? classes.rootWeb : classes.rootMobile}
    >
      <Grid
        container
        direction="row"
        justifyContent={windowSize[0] > 600 ? "space-between" : "center"}
      >
        <Grid
          item
          sm={6}
          justifyContent="flex-start"
          style={windowSize[0] < 600 ? { paddingBottom: 20 } : null}
        >
          <AppleTitle variant={windowSize[0] > 600 ? "h4" : "h6"}>
            Uscite Didattiche
          </AppleTitle>
        </Grid>
        <Grid
          container
          item
          sm={6}
          justifyContent={windowSize[0] > 600 ? "flex-end" : "center"}
        >
          {/* <RoundButton style={{ height: 40, width: 180 }} target="_blank" href="https://dalex.typeform.com/to/frX8D103">
            Nuova Uscita
          </RoundButton> */}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.table}>
        <TripTable />
      </Grid>
    </Grid>
  );
};

Home.defaultProps = {};

export default Home;
