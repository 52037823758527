import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ReactComponent as LogoRing } from '../asset/LogoRing.svg';
import { ReactComponent as LogoDeAmicis } from '../asset/LogoDeAmicis.svg';
import { ReactComponent as LogoAsterisco } from '../asset/LogoAsterisco.svg';
import { makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	bar: {
		height: 80,
	},
	logoRing: {
		height: 54,
		paddingTop: 15,
		paddingBottom: 15,
	},
	logoDeAmicis: {
		height: 47,
		paddingTop: 15,
		paddingBottom: 15,
	},
	logoAsterisco: { height: 47 },
	title: {
		paddingLeft: 20,
	},
}));

const AppNavigator = () => {
	const classes = useStyles();
	return (
		<AppBar
			elevation={2}
			position="fixed"
			className={classes.bar}
			style={{ backgroundColor: 'white' }}
		>
			<Toolbar
				style={{ height: '100%' }}
				sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
			>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid>
						<LogoRing
							className={classes.logoRing}
							style={{ paddingLeft: 26 }}
						/>
					</Grid>

					<Grid>
						<LogoDeAmicis className={classes.logoDeAmicis} />
					</Grid>

					<Grid style={{ width: 54, paddingRight: 26 }}></Grid>
				</Grid>
			</Toolbar>
			<Toolbar
				style={{ height: '100%' }}
				sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}
			>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid>
						<LogoRing className={classes.logoRing} />
					</Grid>
					<Grid>
						<LogoAsterisco className={classes.logoDeAmicis} />
					</Grid>
					<Grid style={{ width: 54 }}></Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

export default AppNavigator;
