export const BASE_URL = 'https://docs.google.com/spreadsheets/d/';
export const SSID = '1LnhJ53MVX6XWjvs-sOEOQ6j57fmhl4hLfUkX9cMylNA';
export const QUERY = '/gviz/tq?';
export const ENDPOINT = BASE_URL + SSID + QUERY;

export const createArrayData = (data) => {
	const arrayData = [];

	for (let i = 0; i < data.rows.length; i++) {
		var lines = {
			startDate: data.rows[i].c[0]?.f ?? '',
			endDate: data.rows[i].c[7]?.f ?? '',
			details: data.rows[i].c[1]?.v ?? '',
			destination: data.rows[i].c[2]?.v ?? '',
			teachers: capitalizeName(data.rows[i].c[3]?.v),
			indirizzo: data.rows[i].c[4]?.v ?? '',
			class: data.rows[i].c[5]?.v ?? '',
			section: data.rows[i].c[6]?.v ?? '',
			completeClassName: fullNameClass(
				data.rows[i].c[4]?.v,
				data.rows[i].c[5]?.v,
				data.rows[i].c[6]?.v
			),
			submittedAt: data.rows[i].c[8]?.f ?? '',
			token: data.rows[i].c[9]?.v ?? '',
		};
		arrayData.push(lines);
	}
	return arrayData;
};

export const fullNameClass = (indirizzo, number, section) => {
	var fullLabel = '';

	if (!!number) {
		fullLabel = number;
	}
	if (!!section) {
		fullLabel = fullLabel + section;
	}

	if (!!indirizzo) {
		fullLabel = fullLabel + ' - ' + indirizzo;
	}
	return fullLabel;
};

export const capitalizeName = (text) => {
	if (!!text) {
		const arr = text.toLowerCase().split(' ');
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const textCap = arr.join(' ');
		return textCap;
	} else {
		return '';
	}
};
