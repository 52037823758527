import * as React from 'react';
import AppNavigator from './components/AppNavigator';
import Home from './components/Home';

function App() {
	return (
		<>
			<AppNavigator />
			<Home />
		</>
	);
}

export default App;
