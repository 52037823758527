import React from 'react';
import { Paper } from '@mui/material';
import { createArrayData, ENDPOINT } from '../utils/utils';
import MaterialTable from '@material-table/core';

const TripTable = () => {
	const [uscite, setUscite] = React.useState([]);

	React.useEffect(() => {
		fetch(ENDPOINT)
			.then((res) => res.text())
			.then((data) => {
				const temp = data.substring(47).slice(0, -2);
				const json = JSON.parse(temp);

				setUscite(createArrayData(json.table));
			});
	}, []);

	return (
		<Paper elevation={0}>
			<MaterialTable
				title=""
				columns={[
					{ title: 'Data di inizio', field: 'startDate' },
					{ title: 'Data di fine', field: 'endDate' },
					{ title: 'Destinazione', field: 'details' },
					{ title: 'Luogo', field: 'destination' },
					{
						title: 'Accompagnatori',
						field: 'teachers',
					},
					{
						title: 'Classe',
						field: 'completeClassName',
					},
				]}
				options={{
					paging: true,
					emptyRowsWhenPaging: false,
					toolbar: true,
					search: true,
					rowStyle: (rowData) => ({
						fontSize: 14,
						height: 80,
					}),
				}}
				data={uscite}
			/>
		</Paper>
	);
};

TripTable.defaultProps = {};

export default TripTable;
